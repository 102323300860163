import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilter from 'src/components/NovaFilters/components/NovaFilter';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'novafilters.exclusion',
};

const Labels: TranslatedComponent = ({ trls }) => {
    return <NovaFilter title={trls[TrlKeys.title]} name={NovaFilterKey.Label} />;
};

export default translation(Labels);
