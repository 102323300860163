import { Fragment, useState, useCallback, useRef, FC, useMemo } from 'react';

import { Input, VSpacing } from '@hh.ru/magritte-ui';
import { RemoteDataProviderResponse, DataProvider } from 'bloko/blocks/suggest/types';
import debounce from 'bloko/common/debounce';

import MagritteFilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import MagritteListItem from 'src/components/NovaFilters/components/Magritte/ListItem';
import MagritteNovaFiltersItem from 'src/components/NovaFilters/components/Magritte/NovaFiltersItem';
import { NovaFilterKey } from 'src/models/novaFilters';

export interface Item {
    count: number;
    id: string;
    title: string;
}

export type ItemMap = Record<string, Item[]>;

interface AdditionalList {
    allItems: ItemMap;
    selectedItems: number[];
    onChange: (selected: string) => void;
    scrollable?: boolean;
    dataProvider: DataProvider;
    placeholder: string;
    filterName: typeof NovaFilterKey.Area | typeof NovaFilterKey.District;
}

const AdditionalList: FC<AdditionalList> = ({
    allItems,
    selectedItems,
    onChange,
    scrollable = true,
    dataProvider,
    placeholder,
    filterName,
}) => {
    const [searchText, setSearchText] = useState('');
    const [list, setList] = useState<{ id: string; text: string }[]>([]);
    const [enableSearch, setEnableSearch] = useState(false);
    const scrollableContainer = useRef<HTMLUListElement>(null);

    const searchDebounced = useMemo(
        () =>
            debounce(async (searchText: string) => {
                let results: RemoteDataProviderResponse;
                try {
                    results = await dataProvider(searchText.trim().toUpperCase());
                } catch (error) {
                    console.error(error);
                    return;
                }
                if (results.items) {
                    setList(results.items.map((item) => ({ ...item, id: `${item.id || ''}` })));
                    setEnableSearch(searchText.length > 0);
                    if (scrollableContainer.current) {
                        scrollableContainer.current.scrollTop = 0;
                    }
                }
            }, 400),
        [dataProvider]
    );

    const onChangeText = useCallback(
        (value: string) => {
            setSearchText(value);
            searchDebounced(value);
        },
        [searchDebounced]
    );

    return (
        <>
            <Input clearable onChange={onChangeText} value={searchText} placeholder={placeholder} />
            <VSpacing default={12} />
            <MagritteFilterList scrollable={scrollable} ref={scrollableContainer}>
                {!enableSearch &&
                    Object.entries(allItems).map(([firstSymbol, items]) => (
                        <Fragment key={firstSymbol}>
                            <MagritteListItem header>{firstSymbol}</MagritteListItem>
                            {items.map((item) => (
                                <MagritteNovaFiltersItem
                                    key={item.id}
                                    item={item}
                                    name={filterName}
                                    onChange={onChange}
                                    checked={selectedItems.includes(Number(item.id))}
                                />
                            ))}
                        </Fragment>
                    ))}
                {enableSearch &&
                    list.map(({ id, text }) => (
                        <MagritteNovaFiltersItem
                            key={id}
                            item={{ title: text, id, count: 0 }}
                            name={filterName}
                            onChange={onChange}
                            checked={selectedItems.includes(Number(id))}
                        />
                    ))}
            </MagritteFilterList>
        </>
    );
};

export default AdditionalList;
