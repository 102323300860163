import { useMemo } from 'react';

import { DataProviderResult, VSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { FormSuggestItem } from 'src/components/Forms/formatter';
import NovaFilterWithSuggest from 'src/components/NovaFilters/components/Magritte/NovaFilterWithSuggest';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroupMap, NovaFilterKey } from 'src/models/novaFilters';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

const AUTOSUGGEST_URL = '/autosuggest/multiprefix/v2';

export interface UniversityItem extends FormSuggestItem {
    acronym?: string;
    synonyms?: string;
}

const formatter = <T extends UniversityItem>(items: T[] | null): DataProviderResult<T> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item.text,
                componentProps: {
                    children: (
                        <>
                            <Text typography="subtitle-1-semibold">{item.acronym}</Text>
                            <VSpacing default={4} />
                            <Text typography="label-2-regular" style="secondary">
                                {item.synonyms}
                            </Text>
                            <Text typography="label-2-regular" style="secondary">
                                {item.text}
                            </Text>
                        </>
                    ),
                },
            },
        ],
    }));
};

const initState = (groups: NovaFilterGroupMap<string>) => {
    if (!groups) {
        return [];
    }
    return Object.values(groups).map(({ id, title }) => ({
        acronym: title,
        id,
        text: title,
    })) as Required<UniversityItem>[];
};

const TrlKeys = {
    title: 'clusters.university',
    label: 'educational.institution.label',
};

const UniversitySuggest: TranslatedComponent = ({ trls }) => {
    const lang = useSelector((state) => state.langs[0]);
    const dataProvider = useMemo(
        () => buildDataProvider<UniversityItem>(AUTOSUGGEST_URL, `university_${lang}`, formatter, getAutosuggestItems),
        [lang]
    );
    const filterUpdate = useNovaFilterUpdate();
    const groups = useSelector((state) => state.searchClusters?.[NovaFilterKey.University].groups);

    return (
        <NovaFilterWithSuggest
            title={trls[TrlKeys.title]}
            selectedValues={initState(groups)}
            name={NovaFilterKey.University}
            filterUpdate={(values) => {
                filterUpdate(
                    values.map(({ id }) => Number(id)),
                    NovaFilterKey.University
                );
            }}
            dataProvider={dataProvider}
            placeholder={trls[TrlKeys.label]}
            normalizeSelectedItem={({ id, acronym }) => ({ id, text: acronym }) as UniversityItem}
        />
    );
};

export default translation(UniversitySuggest);
