import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterWithAdditionalList from 'src/components/NovaFilters/components/NovaFilterWithAdditionalList';
import translation from 'src/components/translation';
import { useAreasDataProvider } from 'src/hooks/useAreasDataProvider';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'searchvacancy.clusters.region',
    placeholder: 'novafilters.area.search',
    add: 'vacancySearch.area.add',
};

const Area: TranslatedComponent = ({ trls }) => {
    const dataProvider = useAreasDataProvider(true);

    return (
        <NovaFilterWithAdditionalList
            filterName={NovaFilterKey.Area}
            dataProvider={dataProvider}
            title={trls[TrlKeys.title]}
            placeholder={trls[TrlKeys.placeholder]}
            placeholderMobile={trls[TrlKeys.title]}
            add={trls[TrlKeys.add]}
        />
    );
};

export default translation(Area);
