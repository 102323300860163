import { FC, PropsWithChildren } from 'react';

import useMagritte from 'src/hooks/useMagritte';

import LinkMoreBloko from 'src/components/NovaFilters/components/Bloko/LinkMore';
import LinkMoreMagritte from 'src/components/NovaFilters/components/Magritte/LinkMore';

interface LinkMoreProps {
    length?: number;
    expanded?: boolean;
    onClick?: () => void;
}

const LinkMore: FC<LinkMoreProps & PropsWithChildren> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? LinkMoreMagritte : LinkMoreBloko;

    return <Component {...props} />;
};

export default LinkMore;
