import { FC, PropsWithChildren } from 'react';

import styles from './chips-select.less';

const NovaChipsSelect: FC<PropsWithChildren> = ({ children }) => {
    return (
        <div className={styles.chipsSelectScroller}>
            <div className={styles.chipsSelectContent}>{children}</div>
        </div>
    );
};

export default NovaChipsSelect;
