import { NovaFilterGroup } from 'src/models/novaFilters';

export const sortMetroByTitleDesc = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>): number => {
    if (a.title > b.title) {
        return 1;
    }
    if (b.title > a.title) {
        return -1;
    }
    return 0;
};
