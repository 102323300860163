import { FC } from 'react';

import numberFormatter from 'bloko/common/numberFormatter';

import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface FilterCountProps {
    count?: number | null;
}

const FilterCount: FC<FilterCountProps> = ({ count }) => {
    if (!count) {
        return null;
    }
    return <>{numberFormatter.format(`${count}`, { groupSeparator: NARROW_NON_BREAKING_SPACE })}</>;
};

export default FilterCount;
