import { FC } from 'react';

import UniversitySuggestBloko from 'src/components/NovaFilters/components/Bloko/resumes/UniversitySuggest';
import UniversitySuggestMagritte from 'src/components/NovaFilters/components/Magritte/resumes/UniversitySuggest';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

const UniversitySuggest: FC = () => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? UniversitySuggestMagritte : UniversitySuggestBloko;

    return <Component />;
};

export default translation(UniversitySuggest);
