import { useState, useMemo, useCallback, useEffect } from 'react';

import { Select, FormLabel, VSpacing as MagritteVSpacing, Link as MagritteLink, Button } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsList from 'src/components/NovaFilters/components/Magritte/NovaChipsList';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, NovaFilterGroup } from 'src/models/novaFilters';

const TrlKeys = {
    add: 'vacancySearch.neighboursArea.add',
    search: 'vacancySearch.neighboursArea.search',
    title: 'vacancySearch.neighboursArea.title',
    save: 'novaModal.save',
};

const NeighboursAreaMobile: TranslatedComponent = ({ trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();

    // global state data
    const { groups: neighbourAreaGroups, selectedValues: initValues } = useSelector(
        (state) => state.searchClusters?.[NovaFilterKey.Neighbours]
    );

    // chips list
    const [neighbourAreas, setNeighbourAreas] = useState<NovaFilterGroup<string>[]>([]);

    const [selectedNeighbourAreas, setSelectedNeighbourAreas] = useState<string[]>(
        initValues?.map((item) => `${item}`) || []
    );

    // need update list after spa/ajax requests
    useEffect(() => {
        setNeighbourAreas(Object.values(neighbourAreaGroups));
    }, [neighbourAreaGroups]);

    const optionsChips = useMemo(
        () => neighbourAreas.filter(({ id }) => selectedNeighbourAreas.includes(id)),
        [neighbourAreas, selectedNeighbourAreas]
    );

    const optionsSelect = useMemo(
        () =>
            Object.values(neighbourAreaGroups).map((item) => ({
                label: item.title,
                value: item.id,
            })),
        [neighbourAreaGroups]
    );

    const onChangeNeighbours = useCallback(
        (area: string) => {
            // update area states
            const index = selectedNeighbourAreas.indexOf(area);
            const newSelectedNeighbours = [...selectedNeighbourAreas];
            if (index === -1) {
                newSelectedNeighbours.push(area);
            } else {
                newSelectedNeighbours.splice(index, 1);
            }
            setSelectedNeighbourAreas(newSelectedNeighbours);
            filterUpdate(newSelectedNeighbours, NovaFilterKey.Neighbours);
        },
        [filterUpdate, selectedNeighbourAreas]
    );

    const onChangeNeighboursBySelect = useCallback(
        (neighbours: string[]) => {
            setSelectedNeighbourAreas(neighbours);
            filterUpdate(neighbours, NovaFilterKey.Neighbours);
        },
        [filterUpdate]
    );

    return (
        <>
            <FormLabel>{trls[TrlKeys.title]}</FormLabel>
            <MagritteVSpacing default={12} />
            <MagritteNovaChipsList
                name={NovaFilterKey.Neighbours}
                options={optionsChips}
                onClear={(id) => {
                    onChangeNeighbours(id);
                    sendCountsRequest();
                }}
            />
            <MagritteVSpacing default={12} />
            <Select
                searchable
                multiple
                type="checkbox"
                triggerProps={{
                    size: 'medium',
                    label: trls[TrlKeys.title],
                    elevateLabel: true,
                    stretched: true,
                }}
                value={selectedNeighbourAreas}
                name={NovaFilterKey.Neighbours}
                options={optionsSelect}
                renderTrigger={({ onChange, expanded }) => {
                    return (
                        <MagritteLink
                            iconLeft={PlusOutlinedSize16}
                            Element="button"
                            data-qa="novafilters-mobile-add-neighbours-areas"
                            onClick={() => {
                                onChange?.(!expanded);
                            }}
                        >
                            {trls[TrlKeys.add]}
                        </MagritteLink>
                    );
                }}
                onChange={onChangeNeighboursBySelect}
                applyChangesButton={
                    <Button mode="primary" style="accent">
                        {trls[TrlKeys.save]}
                    </Button>
                }
            />
            <MagritteVSpacing default={24} />
        </>
    );
};

export default translation(NeighboursAreaMobile);
