import { Link as MagritteLink, VSpacing, Divider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { usePlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { Platform } from 'src/models/locale.types';

import SearchType from 'src/components/NovaFilters/SearchType';

const ARTICLE_LINKS = {
    [SearchType.Vacancy]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: undefined },
    [SearchType.VacancyMap]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: undefined },
    [SearchType.EmployerVacancy]: { [Platform.HeadHunter]: '/article/1175', [Platform.Zarplata]: undefined },
    [SearchType.Resume]: {
        [Platform.HeadHunter]: '/article/23786',
        [Platform.Zarplata]: '/article/25295?hhtmFrom=resume_search_form',
    },
};

const TrlKeys = {
    link: 'novafilters.searchLanguageLink',
};

interface Props {
    searchType: SearchType;
}

const SearchLanguageLink: TranslatedComponent<Props> = ({ trls, searchType }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;
    const platform = usePlatform();

    if (searchType === SearchType.EmployerVacancy) {
        return null;
    }

    return (
        <>
            {!isResumeSearch && <Divider marginBottom={24} />}
            <MagritteLink
                href={ARTICLE_LINKS[searchType][platform] || ARTICLE_LINKS[searchType][Platform.HeadHunter]}
                enableVisited
            >
                {trls[TrlKeys.link]}
            </MagritteLink>
            <VSpacing default={24} />
        </>
    );
};

export default translation(SearchLanguageLink);
