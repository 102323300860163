import { FormLabel, VSpacing as MagritteVSpacing, Radio as MagritteRadio } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsSelect, {
    ChipsSelectType as MagritteChipsSelectType,
} from 'src/components/NovaFilters/components/Magritte/NovaChipsSelect';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    doesNotMatter: 'vacancySearch.experience.doesNotMatter',
    title: 'searchvacancy.clusters.experience',
};

interface Props {
    name: typeof NovaFilterKey.Experience;
}

const Experience: TranslatedComponent<Props> = ({ trls, name }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Experience].groups);
    const selectedValues = useSelector((state) => state.searchClusters[NovaFilterKey.Experience].selectedValues) || [];
    const onChangeExperience = (newValue: string[]) => {
        filterUpdate(newValue, NovaFilterKey.Experience);
    };

    return (
        <MagritteNovaControl
            mobileView={
                <>
                    <FormLabel>{trls[TrlKeys.title]}</FormLabel>
                    <MagritteVSpacing default={12} />
                    <MagritteNovaChipsSelect
                        name={name}
                        selected={selectedValues}
                        options={Object.values(groups)}
                        selectType={MagritteChipsSelectType.Radio}
                        onChange={(experience) => {
                            filterUpdate(experience, NovaFilterKey.Experience);
                            sendCountsRequest();
                        }}
                    />
                    <MagritteVSpacing default={24} />
                </>
            }
        >
            <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                <MagritteNovaFilterItemWrapper
                    left={<MagritteRadio onChange={() => onChangeExperience([])} checked={!selectedValues.length} />}
                    title={trls[TrlKeys.doesNotMatter]}
                />
                {Object.values(groups).map(({ id, title, count, disabled }) => (
                    <MagritteNovaFilterItemWrapper
                        key={id}
                        left={
                            <MagritteRadio
                                value={id}
                                onChange={() => onChangeExperience([id])}
                                checked={selectedValues[0] === id}
                            />
                        }
                        title={title}
                        disabled={disabled}
                        count={count}
                    />
                ))}
            </MagritteNovaFilterWrapper>
        </MagritteNovaControl>
    );
};

export default translation(Experience);
