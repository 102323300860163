import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

import RegionSuggestMagritte from 'src/components/NovaFilters/components/Magritte/RegionSuggest';

interface Props {
    title: string;
    name: typeof NovaFilterKey.Citizenship | typeof NovaFilterKey.WorkTicket;
}

const RegionSuggest: TranslatedComponent<Props> = (props) => {
    return <RegionSuggestMagritte {...props} />;
};

export default translation(RegionSuggest);
