import {
    createDataProvider,
    decorateWithResponseFormatter,
    DataProviderResult,
    defaultFetcher,
    decorateWithQueryTransofrmer,
    DataProvider,
} from '@hh.ru/magritte-ui';

import { updateUrl } from 'Modules/url';

export interface Autosuggest {
    id: number | string;
}

export interface AutosuggestWithText extends Autosuggest {
    text: string;
}

interface AutosuggestResponse<T extends Autosuggest> {
    items: T[];
}

export interface CancelCallback {
    (callback: VoidFunction): void;
}

// eslint-disable-next-line etc/no-misused-generics
export const getAutosuggestItems = <T extends Autosuggest>(url: string, onCancel: CancelCallback): Promise<T[]> =>
    defaultFetcher<AutosuggestResponse<T>>(url, onCancel).then((data) => data?.items ?? []);

const normalizeText = (value?: string) => value?.toLowerCase().trim() ?? '';

interface FetcherWithAutoSelectProps<T> {
    fetcher: (query: string, onCancel: CancelCallback) => Promise<T[]>;
    inputValue?: string;
    onAutoSelect?: (data: T) => void;
    onBlurSaveFirstValue?: (data: T[]) => void;
}

/**
 *
 * Создает фетчер для дата провайдера компонента Suggest из Magritte с возможностью автовыбора, когда запрос полностью совпадает и при блюре.
 *
 * @param {(query: string, onCancel: CancelCallback) => Promise<T[]>} fetcher - Функция получения данных.
 * @param {string} inputValue - Введеное в инпут значение.
 * @param {(data: T) => void} onAutoSelect - Функция для обработки выбранного значения при полном совпадении запроса.
 * @param {(data: T) => void} onBlurSaveFirstValue - Функция сохранения первого значения при блюре.
 * @return {(url: string, onCancel: CancelCallback) => Promise<T[]>} - Функция получения данных с оберткой.
 */
export const fetcherWithAutoSelect = <T extends AutosuggestWithText>({
    fetcher,
    inputValue,
    onAutoSelect,
    onBlurSaveFirstValue,
}: FetcherWithAutoSelectProps<T>): ((url: string, onCancel: CancelCallback) => Promise<T[]>) => {
    const wrapper = (url: string, onCancel: CancelCallback) =>
        fetcher(url, onCancel).then((data) => {
            if (onBlurSaveFirstValue) {
                onBlurSaveFirstValue(data);
            }
            if (
                onAutoSelect &&
                data &&
                data.length === 1 &&
                normalizeText(data[0].text) === normalizeText(inputValue)
            ) {
                onAutoSelect(data[0]);
            }

            return data;
        });

    return wrapper;
};

/**
 *
 * Создает data provider для компонента Suggest из Magritte с возможностью автовыбора при полном совпадении запроса и автовыбора при блюре
 *
 * @param {string} url - URL-адрес для получения данных.
 * @param {string} dynamicUrlPart - Динамическая часть URL-адреса. (Пример: параметры q и d)
 * @param {(data: T[] | null) => DataProviderResult<T>} formatter - Форматтер данных, с помощью которого можно настроить отображаемый вид выпадающего списка.
 * @param {(url: string, onCancel: CancelCallback) => Promise<T[]>} fetcher - Функция получения данных.
 * @param {{ minCharsCount?: number; debounceTimeout?: number; onBlurSaveFirstValue?: (data: T[]) => void; onAutoSelect?: (data: T) => void; inputValue?: string; }} config - Параметры конфигурации с помощью которых можно настроить дополнительное поведение.
 * @returns {DataProvider<T>} Дата провайдер.
 */
export const buildDataProvider = <T extends AutosuggestWithText>(
    url: string,
    dynamicUrlPart: string,
    formatter: (data: T[] | null) => DataProviderResult<T>,
    fetcher: (url: string, onCancel: CancelCallback) => Promise<T[]>,
    config: {
        minCharsCount?: number;
        debounceTimeout?: number;
        onBlurSaveFirstValue?: (data: T[]) => void;
        onAutoSelect?: (data: T) => void;
        inputValue?: string;
    } = { minCharsCount: 2, debounceTimeout: 300 }
): DataProvider<T> => {
    const { minCharsCount, debounceTimeout, onBlurSaveFirstValue, inputValue, onAutoSelect } = config;

    const queryString = (q: string) => updateUrl(url, { q, d: dynamicUrlPart });

    const decoratedFetcher = decorateWithQueryTransofrmer(
        fetcherWithAutoSelect({
            fetcher,
            inputValue,
            onAutoSelect,
            onBlurSaveFirstValue,
        }),
        queryString
    );

    const provider = createDataProvider({
        fetcher: decorateWithResponseFormatter(decoratedFetcher, formatter),
        minCharsCount,
        debounceTimeout,
    });

    return provider;
};
