import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsItem from 'src/components/NovaFilters/components/Magritte/NovaChipsItem';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'src/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, NovaFilterCompany } from 'src/models/novaFilters';

const getCompanyName = (cluster: NovaFilterCompany) => {
    const { groups, selectedValues } = cluster;
    return selectedValues.map((id) => groups[id].name).join(', ');
};

const TrlKeys = {
    title: 'searchvacancy.clusters.company',
};

interface Props {
    name: typeof NovaFilterKey.Company;
}

const Company: TranslatedComponent<Props> = ({ name, trls }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const cluster = useSelector(({ searchClusters }) => searchClusters?.[name]);

    if (!cluster || Object.values(cluster.groups).length === 0 || !cluster.selectedValues.length) {
        return null;
    }

    return (
        <MagritteNovaControl
            mobileView={
                <MagritteNovaChipsItem
                    title={trls[TrlKeys.title]}
                    value={getCompanyName(cluster)}
                    onDelete={() => {
                        filterUpdate([], NovaFilterKey.Company);
                        sendCountsRequest();
                    }}
                />
            }
        >
            <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                <MagritteNovaFilterContent name={name} />
            </MagritteNovaFilterWrapper>
        </MagritteNovaControl>
    );
};

export default translation(Company);
