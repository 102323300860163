import { useMemo } from 'react';

import { DataProvider as MagritteDataProvider } from '@hh.ru/magritte-ui';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';

import AreaId from 'HHC/areaId';
import { updateUrl } from 'Modules/url';
import formatter, { FormSuggestItem } from 'src/components/Forms/formatter';
import { buildDataProvider, getAutosuggestItems } from 'src/utils/suggest/buildDataProvider';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const WILDCARD = '%QUERY%';
const REMOTE = `/autosuggest/multiprefix/v2`;

interface UseAreasMagritteDataProviderProps {
    inputValue: string;
    allowRegions: boolean;
    rootAreaId?: string | null;
    minCharsCount?: number;
    onBlurSaveFirstArea?: (area: AreaSuggest[]) => void;
    onAutoSelect?: (area: AreaSuggest) => void;
}

export interface AreaSuggest extends FormSuggestItem {
    type: number;
}

export const useAreasDataProvider = (allowRegions: boolean, rootAreaId: string | null = null): DataProvider => {
    const lang = useSelector((state) => state.langs[0]);
    const isZarplata = useIsZarplataPlatform();
    const filter = isZarplata ? AreaId.Russia : rootAreaId;

    const remoteUrl = updateUrl(REMOTE, {
        d: allowRegions ? `areas_${lang}` : `area_leafs_${lang}`,
        f: filter,
    });

    return useMemo(() => createRemoteDataProvider(`${remoteUrl}&q=${WILDCARD}`, WILDCARD), [remoteUrl]);
};

export const useAreasMagritteDataProvider = ({
    allowRegions,
    onBlurSaveFirstArea,
    onAutoSelect,
    inputValue,
    rootAreaId = null,
    minCharsCount = 2,
}: UseAreasMagritteDataProviderProps): MagritteDataProvider<AreaSuggest> => {
    const lang = useSelector((state) => state.langs[0]);
    const isZarplata = useIsZarplataPlatform();
    const filter = isZarplata ? AreaId.Russia : rootAreaId;

    const dynamicUrlPart = allowRegions ? `areas_${lang}` : `area_leafs_${lang}`;

    const remoteUrl = updateUrl(REMOTE, {
        f: filter,
    });

    const searchProvider = useMemo(
        () =>
            buildDataProvider<AreaSuggest>(remoteUrl, dynamicUrlPart, formatter, getAutosuggestItems, {
                minCharsCount,
                onBlurSaveFirstValue: onBlurSaveFirstArea,
                inputValue,
                onAutoSelect,
            }),
        [remoteUrl, dynamicUrlPart, minCharsCount, onBlurSaveFirstArea, inputValue, onAutoSelect]
    );

    return searchProvider;
};
