import { useMemo, useState } from 'react';

import InputText from 'bloko/blocks/inputText';
import Suggest from 'bloko/blocks/suggest';
import createRemoteDataProvider from 'bloko/blocks/suggest/createRemoteDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';
import TagList from 'bloko/blocks/tagList';
import Tag from 'bloko/blocks/tagList/tag';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterWrapper from 'src/components/NovaFilters/components/Bloko/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import UniversitySuggestItem from 'src/components/ResumeSearch/UniversitySuggestItem';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterGroupMap, NovaFilterKey } from 'src/models/novaFilters';
import { UniversityItem } from 'src/models/search/advancedSearch';

const REMOTE = '/autosuggest/multiprefix/v2?q=%QUERY%&d=university_';
const WILDCARD = '%QUERY%';
const SUGGEST_EMPTY_ITEM = { id: '', text: '', acronym: '' };

const TrlKeys = {
    title: 'clusters.university',
    label: 'educational.institution.label',
};

const initState: (groups: NovaFilterGroupMap<string>) => UniversityItem[] = (groups) => {
    if (!groups) {
        return [];
    }
    return Object.values(groups).map(({ id, title }) => ({ acronym: title, id, text: title }));
};

const UniversitySuggest: TranslatedComponent = ({ trls }) => {
    const lang = useSelector((state) => state.langs[0]);
    const dataProvider = useMemo(
        () => createRemoteDataProvider(REMOTE + lang, WILDCARD),
        [lang]
    ) as DataProvider<UniversityItem>;
    const filterUpdate = useNovaFilterUpdate();
    const groups = useSelector((state) => state.searchClusters?.[NovaFilterKey.University].groups);

    const [suggestValue, setSuggestValue] = useState<UniversityItem>(SUGGEST_EMPTY_ITEM);
    const [selectedItems, setSelectedItems] = useState<UniversityItem[]>(initState(groups));

    const onSuggestChange = (item: UniversityItem) => {
        setSuggestValue(item);
        if (!item) {
            return;
        }
        if (!selectedItems.map(({ id }) => id).includes(item.id)) {
            const newItems = selectedItems.slice();
            newItems.push(item);

            setSuggestValue(SUGGEST_EMPTY_ITEM);
            setSelectedItems(newItems);
            filterUpdate(
                newItems.map(({ id }) => Number(id)),
                NovaFilterKey.University
            );
        }
    };

    const onSuggestRemove = (itemId: string) => {
        const newItems = selectedItems.filter(({ id }) => id !== itemId);
        setSelectedItems(newItems);
        filterUpdate(
            newItems.map(({ id }) => Number(id)),
            NovaFilterKey.University
        );
    };

    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <Suggest
                dataProvider={dataProvider}
                value={suggestValue}
                onChange={onSuggestChange}
                selectOnBlur={false}
                limit={5}
                itemContent={UniversitySuggestItem}
                data-qa={`serp__novafilter-item-${NovaFilterKey.University}`}
            >
                <InputText
                    placeholder={trls[TrlKeys.label]}
                    data-qa={`novafilter-item-input__${NovaFilterKey.University}`}
                />
            </Suggest>
            <TagList
                removable
                items={selectedItems.map(({ id, acronym }) => (
                    <Tag key={id} onRemove={() => onSuggestRemove(id || '')}>
                        {acronym}
                    </Tag>
                ))}
            />
        </NovaFilterWrapper>
    );
};

export default translation(UniversitySuggest);
