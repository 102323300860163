import { FC } from 'react';

import { FormLabel, VSpacing, CustomChip } from '@hh.ru/magritte-ui';

interface NovaChipsItemProps {
    title: string;
    value: string;
    onDelete: () => void;
}

const NovaChipsItem: FC<NovaChipsItemProps> = ({ title, value, onDelete }) => (
    <>
        <FormLabel>{title}</FormLabel>
        <VSpacing default={12} />
        <CustomChip onDelete={onDelete}>{value}</CustomChip>
        <VSpacing default={24} />
    </>
);

export default NovaChipsItem;
