import { ReactNode, FC } from 'react';

import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';

type RenderPointIcon = (color: string, isMagritte?: boolean, containerSize?: 22 | 24) => ReactNode;

export const renderPointIcon: RenderPointIcon = (color, isMagritte, containerSize: 22 | 24 = 24) => {
    if (isMagritte) {
        return (
            <svg
                width={containerSize}
                height={containerSize}
                viewBox={`0 0 ${containerSize} ${containerSize}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx={containerSize / 2} cy={containerSize / 2} r={5} fill={color} />
            </svg>
        );
    }
    return (
        <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="5.5" r="4" fill={color} />
        </svg>
    );
};

interface MetroPointProps {
    name: string;
    color: string;
}

const MetroPoint: FC<MetroPointProps> = ({ name, color }) => (
    <span>
        {color && <span className="novafilters-metro-icon">{renderPointIcon(color)}</span>}
        <FilterTitle title={name} truncated />
    </span>
);

export default MetroPoint;
