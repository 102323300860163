import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

interface ListItemProps {
    header?: boolean;
}

const ListItem: FC<ListItemProps & PropsWithChildren> = ({ children, header = false }) => (
    <li
        className={classnames('novafilters-list__item', { 'novafilters-list__item_header': header })}
        data-qa="serp__novafilter-item"
    >
        {children}
    </li>
);

export default ListItem;
