import { FC } from 'react';

import Text, { TextImportance } from 'bloko/blocks/text';
import numberFormatter from 'bloko/common/numberFormatter';

import { NARROW_NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface FilterCountProps {
    count?: number | null;
}

const FilterCount: FC<FilterCountProps> = ({ count }) => {
    if (!count) {
        return null;
    }
    return (
        <>
            &nbsp;
            <span className="novafilters-list__item-counts">
                <Text Element="span" importance={TextImportance.Tertiary}>
                    {numberFormatter.format(`${count}`, { groupSeparator: NARROW_NON_BREAKING_SPACE })}
                </Text>
            </span>
        </>
    );
};

export default FilterCount;
