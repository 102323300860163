import { FC, ReactElement } from 'react';

import { Checkbox, Cell, CellText, CheckableCard } from '@hh.ru/magritte-ui';

import { NovaFilterGroup } from 'src/models/novaFilters';

import styles from './styles.less';

interface NovaFiltersCardItemProps {
    item: NovaFilterGroup<string | number>;
    name: string;
    checked: boolean;
    indeterminate?: boolean;
    onChange: (id: string) => void;
    left?: ReactElement;
}

const NovaFiltersCardItem: FC<NovaFiltersCardItemProps> = ({
    item: { title, id },
    name,
    checked,
    indeterminate,
    onChange,
    left,
}) => {
    return (
        <CheckableCard
            flexible
            type="checkbox"
            checked={checked || indeterminate}
            padding={16}
            borderRadius={12}
            onChange={() => {
                onChange(`${id}`);
            }}
        >
            <Cell
                left={left}
                right={
                    <div className={styles.checkbox}>
                        <Checkbox
                            name={name}
                            value={id}
                            checked={checked}
                            indeterminate={indeterminate}
                            dataQaCheckbox={`serp__novafilter-${name}-${id}`}
                            readOnly
                        />
                    </div>
                }
            >
                <CellText data-qa="serp__novafilter-item-text">{title}</CellText>
            </Cell>
        </CheckableCard>
    );
};

export default NovaFiltersCardItem;
