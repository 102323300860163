import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaFilter from 'src/components/NovaFilters/components/Magritte/NovaFilter';
import { NovaFilterProps } from 'src/components/NovaFilters/components/NovaFilter';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'novafilters.exclusion',
};

const Labels: TranslatedComponent<NovaFilterProps> = ({ trls, name }) => {
    return <MagritteNovaFilter title={trls[TrlKeys.title]} name={name} shouldSendCountsRequest />;
};

export default translation(Labels);
