import { Link, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    remove: 'vacancyresponse.filters.language.remove',
};

type Props = {
    onClick: () => void;
    dataQa: string;
};

const RemoveLink: TranslatedComponent<Props> = ({ trls, onClick, dataQa }) => {
    return (
        <>
            <VSpacing default={8} />
            <Link typography="label-2-regular" onClick={onClick} data-qa={dataQa}>
                {trls[TrlKeys.remove]}
            </Link>
            <VSpacing default={16} />
        </>
    );
};

export default translation(RemoveLink);
