import { ReactNode, FC } from 'react';

import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';

type RenderLineIcon = (color: string, isMagritte?: boolean, containerSize?: 22 | 24) => ReactNode;

export const renderLineIcon: RenderLineIcon = (color, isMagritte, containerSize = 24) => {
    const width = 6;
    const height = 16;
    if (isMagritte) {
        return (
            <svg
                width={containerSize}
                height={containerSize}
                viewBox={`0 0 ${containerSize} ${containerSize}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x={containerSize / 2 - width / 2}
                    y={containerSize / 2 - height / 2}
                    width={width}
                    height={height}
                    rx={width / 2}
                    fill={color}
                />
            </svg>
        );
    }

    return (
        <svg width="8" height="17" viewBox="0 0 8 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="8.5" r="4" fill={color} />
            <rect x="2" y="0.5" width="4" height="3" fill={color} />
            <rect x="2" y="13.5" width="4" height="3" fill={color} />
        </svg>
    );
};

interface MetroLineProps {
    name: string;
    color: string;
}

const MetroLine: FC<MetroLineProps> = ({ name, color }) => (
    <span>
        {color && <span className="novafilters-metro-icon novafilters-metro-icon_line">{renderLineIcon(color)}</span>}
        <FilterTitle title={name} truncated />
    </span>
);

export default MetroLine;
