import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { urlParser } from '@hh.ru/browser-api-utils';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { useReplace } from '@hh.ru/redux-spa-middleware';

import Debug from 'HHC/Debug';
import { useNotification } from 'src/components/Notifications/Provider';
import { useUpdateAbortController } from 'src/components/NovaFilters/NovaFilterUpdateContext';
import { useSearchParams } from 'src/components/NovaFilters/actions/sendFilterForm/getSearchParams/useSearchParams';
import useToggleSearchPreference from 'src/components/SearchSorts/hooks/useToggleSearchPreference';
import { useSelector } from 'src/hooks/useSelector';
import { setSearchLineText } from 'src/models/employerVacancySearch';
import { ITEMS_ON_CHAMELEON_PAGE } from 'src/models/employerVacancySearch/const';
import { getTemplateCriteria } from 'src/models/employerVacancyTemplateFilter/getTemplateCriteria';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';
import { EmployerCriteria } from 'src/models/vacancySearchCriteria';

import fetchEmployerVacancies from 'src/api/employerVacancySearch/fetchEmployerVacancies';

const useSharedFetchParam = () => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const replace = useReplace();
    const abortAndGetUpdatedSignal = useUpdateAbortController();
    const { isMobile } = useBreakpoint();
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    return { dispatch, addNotification, replace, abortAndGetUpdatedSignal, isMobile, employerId, hasChameleon };
};

type UseSetEmployerVacanciesByNovaFilters = () => {
    setVacanciesByPageNumber: (page: number, showLoad?: boolean) => void;
    setVacanciesByCriteria: (params: Partial<EmployerCriteria>) => void;
};

export const useSetEmployerVacanciesByNovaFilters: UseSetEmployerVacanciesByNovaFilters = () => {
    const searchParams = useSearchParams();
    const { dispatch, addNotification, replace, abortAndGetUpdatedSignal, isMobile } = useSharedFetchParam();

    const setVacanciesByCriteria = useCallback(
        (params: Partial<EmployerCriteria>) => {
            const query = urlParser.stringify({ ...searchParams, ...params } as never);
            const abortSignal = abortAndGetUpdatedSignal();
            dispatch(
                fetchEmployerVacancies({
                    query,
                    abortSignal,
                    isMobile,
                    addNotification,
                    replace,
                })
            ).catch(console.error);
        },
        [abortAndGetUpdatedSignal, addNotification, dispatch, isMobile, replace, searchParams]
    );
    const setVacanciesByPageNumber = useCallback(
        (page: number) => {
            setVacanciesByCriteria({
                [CriteriaKey.Page]: page,
            });
        },
        [setVacanciesByCriteria]
    );

    return { setVacanciesByPageNumber, setVacanciesByCriteria };
};

type UseSetEmployerVacancyList = () => {
    setEmployerVacancyFilter: (params?: Partial<EmployerCriteria>) => void;
};

export const useSetEmployerVacancyList: UseSetEmployerVacancyList = () => {
    const { dispatch, addNotification, replace, abortAndGetUpdatedSignal, isMobile, employerId, hasChameleon } =
        useSharedFetchParam();

    const setEmployerVacancyFilter = useCallback(
        (params: Partial<EmployerCriteria> = {}) => {
            if (!employerId) {
                Debug.log('out error', new Error('employerId is missing'));
                return;
            }
            const query = urlParser.stringify({
                ...params,
                [CriteriaKey.ItemsOnPage]: hasChameleon ? ITEMS_ON_CHAMELEON_PAGE : undefined,
                [CriteriaKey.CurrentEmployerId]: params[CriteriaKey.CurrentEmployerId]
                    ? params[CriteriaKey.CurrentEmployerId]
                    : employerId,
                getVacancyCount: 'true',
            } as never);
            const abortSignal = abortAndGetUpdatedSignal();
            dispatch(
                fetchEmployerVacancies({
                    query,
                    abortSignal,
                    isMobile,
                    addNotification,
                    replace,
                })
            ).catch(console.error);
        },
        [abortAndGetUpdatedSignal, addNotification, dispatch, employerId, hasChameleon, isMobile, replace]
    );

    return { setEmployerVacancyFilter };
};

type UseSetEmployerVacancyListDefault = () => {
    setEmployerVacancyListDefault: () => void;
};

export const useSetEmployerVacancyListDefault: UseSetEmployerVacancyListDefault = () => {
    const dispatch = useDispatch();
    const { setEmployerVacancyFilter } = useSetEmployerVacancyList();
    const toggleSearchPreference = useToggleSearchPreference();
    const templateFilter = useSelector((state) => state.employerVacancyTemplateFilter);

    const setEmployerVacancyListDefault = useCallback(() => {
        dispatch(setSearchLineText(''));
        toggleSearchPreference(false);
        setEmployerVacancyFilter({
            ...templateFilter,
            [CriteriaKey.HiddenFilters]: getTemplateCriteria(templateFilter),
        });
    }, [dispatch, setEmployerVacancyFilter, templateFilter, toggleSearchPreference]);

    return { setEmployerVacancyListDefault };
};

export default useSetEmployerVacanciesByNovaFilters;
