import { FC, ReactNode, PropsWithChildren } from 'react';

import { Divider, Text, VSpacing } from '@hh.ru/magritte-ui';

import styles from './styles.less';

interface NovaFilterWrapperPros {
    title?: ReactNode;
    titleSideElement?: ReactNode;
    withDivider?: boolean;
}

const NovaFilterWrapper: FC<NovaFilterWrapperPros & PropsWithChildren> = ({
    title,
    titleSideElement,
    withDivider,
    children,
}) => (
    <fieldset data-qa="serp__novafilter-group" style={{ minWidth: 'auto' }}>
        {title && (
            <>
                <div className={styles.title} data-qa="serp__novafilter-group-title">
                    <Text typography="subtitle-1-semibold">
                        <legend>{title}</legend>
                    </Text>
                    {!!titleSideElement && titleSideElement}
                </div>
                <VSpacing default={12} />
            </>
        )}
        <div>{children}</div>
        {withDivider ? <Divider marginTop={12} marginBottom={12} /> : <VSpacing default={24} />}
    </fieldset>
);

export default NovaFilterWrapper;
