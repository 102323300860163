import { useState, ChangeEvent, FC, ReactElement } from 'react';

import { CheckableChip, ChipsContainer } from '@hh.ru/magritte-ui';

export enum ChipsSelectType {
    Checkbox = 'checkbox',
    Radio = 'radio',
}

export interface NovaChipsOption<T extends string> {
    id: T;
    title: ReactElement | string;
    addon?: ReactElement;
}

export interface NovaChipsSelectProps {
    name: string;
    options: NovaChipsOption<string>[];
    selected: string[];
    onChange: (values: string[]) => void;
    selectType?: ChipsSelectType;
}

const NovaChipsSelect: FC<NovaChipsSelectProps> = ({
    name,
    options,
    selected,
    onChange,
    selectType = ChipsSelectType.Checkbox,
}) => {
    const [values, setValues] = useState([...selected]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (values.includes(value)) {
            if (selectType === ChipsSelectType.Radio) {
                return;
            }
            const newValues = values.filter((item) => item !== value);
            setValues(newValues);
            onChange(newValues);
        } else {
            if (selectType === ChipsSelectType.Radio) {
                setValues([value]);
                onChange([value]);
                return;
            }
            const newValues = [...values, value];
            setValues(newValues);
            onChange(newValues);
        }
    };

    return (
        <ChipsContainer scrollable noWrap>
            {options.map(({ id, title }) => (
                <CheckableChip
                    type={selectType}
                    key={`${name}_${id}`}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    onChange={handleChange}
                    checked={values.includes(id)}
                    value={id}
                    id={id}
                >
                    {title}
                </CheckableChip>
            ))}
        </ChipsContainer>
    );
};

export default NovaChipsSelect;
