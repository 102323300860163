import classnames from 'classnames';

import { Checkbox, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import useChangeVerifiedFilters from 'src/components/NovaFilters/resumes/useChangeVerifiedFilters';
import RemoveButton from 'src/components/ResumeLanguageFilter/RemoveButton';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import LanguageLevelSelect from 'src/components/ResumeLanguageFilter/Magritte/LanguageLevelSelect';
import LanguageSelect from 'src/components/ResumeLanguageFilter/Magritte/LanguageSelect';

type Props = {
    languageSection: string;
    value: string[];
    selectedLanguages: string[];
    preTitleDataQa: string;
    isVertical?: boolean;
    updateValue: (oldLanguage: string, newLanguage: string) => void;
    removeValue: (removingLanguage: string) => void;
};

const TrlKeys = {
    addVerified: 'clusters.verifiedLanguages',
};
const FormItemWithVerification: TranslatedComponent<Props> = ({
    trls,
    languageSection,
    removeValue,
    selectedLanguages,
    updateValue,
    value,
    preTitleDataQa,
    isVertical = true,
}) => {
    const [code, grade] = languageSection.split('.');
    const searchCluster = useSelector(({ searchClusters }) => searchClusters);
    const levels = useSelector((state) => state.languageLevels.level);
    const { searchId, handleChangeVerifiedLanguage, resetVerifiedLanguage } = useChangeVerifiedFilters(languageSection);
    const verifiedLanguages = searchCluster[NovaFilterKey.VerifiedLanguages];
    const selectedValues = verifiedLanguages.selectedValues;

    return (
        <div className={classnames({ 'resume-search-item__language': !isVertical })}>
            <LanguageSelect
                code={code}
                onChange={(code) => updateValue(languageSection, `${code}.${grade}`)}
                selectedLanguages={selectedLanguages}
                dataQa={`${preTitleDataQa}-filter-language`}
            />
            <VSpacing default={8} />
            <LanguageLevelSelect
                grade={grade}
                onChange={(grade) => {
                    resetVerifiedLanguage();
                    updateValue(languageSection, `${code}.${grade}`);
                }}
                levels={levels}
                dataQa={`${preTitleDataQa}-filter-language-level`}
            />
            {searchId !== null && (
                <>
                    <VSpacing default={8} />
                    <NovaFilterItemWrapper
                        left={
                            <Checkbox
                                onChange={() => {
                                    handleChangeVerifiedLanguage();
                                    updateValue(languageSection, value[0]);
                                }}
                                checked={selectedValues.includes(searchId)}
                            />
                        }
                        title={trls[TrlKeys.addVerified]}
                        count={verifiedLanguages.groups[searchId].count}
                    />
                </>
            )}
            {!isVertical && (
                <RemoveButton
                    onClick={() => removeValue(languageSection)}
                    dataQa={`${preTitleDataQa}__language-delete`}
                />
            )}
        </div>
    );
};

export default translation(FormItemWithVerification);
