import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaSearchOrder from 'src/components/NovaFilters/components/Magritte/SortingGroup/NovaSearchOrder';
import MagritteNovaSearchPeriod from 'src/components/NovaFilters/components/Magritte/SortingGroup/NovaSearchPeriod';
import translation from 'src/components/translation';

const SortingGroup: TranslatedComponent = () => {
    return (
        <MagritteNovaControl
            mobileView={
                <>
                    <MagritteNovaSearchOrder />
                    <MagritteNovaSearchPeriod />
                </>
            }
        />
    );
};

export default translation(SortingGroup);
