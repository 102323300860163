import { FC, PropsWithChildren } from 'react';

import useMagritte from 'src/hooks/useMagritte';

import ListItemBloko from 'src/components/NovaFilters/components/Bloko/ListItem';
import ListItemMagritte from 'src/components/NovaFilters/components/Magritte/ListItem';

interface ListItemProps {
    header?: boolean;
}

const ListItem: FC<ListItemProps & PropsWithChildren> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? ListItemMagritte : ListItemBloko;

    return <Component {...props} />;
};

export default ListItem;
