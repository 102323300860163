import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import RegionSuggest from 'src/components/NovaFilters/RegionSuggest';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    title: 'employer.resumesSearch.citizenship',
};

const Citizenship: TranslatedComponent = ({ trls }) => {
    return <RegionSuggest title={trls[TrlKeys.title]} name={NovaFilterKey.Citizenship} />;
};

export default translation(Citizenship);
