import { useMemo, FC } from 'react';

import { Cell, CellText, Action } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault, CoreField } from 'bloko/common/tree/types';

import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

interface SelectedRolesProps {
    collection: TreeCollection<AdditionalDefault>;
    selectedValues: string[];
    onClear: () => void;
}

const SelectedRoles: FC<SelectedRolesProps> = ({ collection, selectedValues, onClear }) => {
    const value = useMemo(() => {
        if (selectedValues.length === 0 || !collection) {
            return null;
        }
        return selectedValues.map((id, index) => {
            const role = collection.getModel(id);
            if (!role) {
                return '';
            }
            return `${index > 0 ? `${NON_BREAKING_SPACE}• ` : ''}${role[CoreField.Text]}`;
        });
    }, [collection, selectedValues]);
    if (!value) {
        return null;
    }

    return (
        <Cell align="top" right={<Action icon={CrossOutlinedSize24} mode="secondary" onClick={onClear} />}>
            <CellText>
                {selectedValues.map((id, index) => {
                    const role = collection.getModel(id);
                    if (!role) {
                        return '';
                    }
                    return `${index > 0 ? `${NON_BREAKING_SPACE}· ` : ''}${role[CoreField.Text]}`;
                })}
            </CellText>
        </Cell>
    );
};

export default SelectedRoles;
