import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'src/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

import NeighboursAreaMobile from 'src/components/NovaFilters/vacancies/Neighbours/NeighboursMobile';

const TrlKeys = {
    title: 'clusters.neighbours',
};
const Neighbours: TranslatedComponent = ({ trls }) => {
    return (
        <MagritteNovaControl mobileView={<NeighboursAreaMobile title={trls[TrlKeys.title]} />}>
            <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                <MagritteNovaFilterContent name={NovaFilterKey.Neighbours} />
            </MagritteNovaFilterWrapper>
        </MagritteNovaControl>
    );
};

export default translation(Neighbours);
