import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilterContent from 'src/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import translation from 'src/components/translation';
import { NovaFilterGroup, NovaFilterKey } from 'src/models/novaFilters';

interface EducationLevelProps {
    name: NovaFilterKey;
}

const TrlKeys = {
    title: 'clusters.educationLevel',
};

const educationLevelSort = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => {
    if (!a.order || !b.order) {
        return 0;
    }
    if (a.order > b.order) {
        return 1;
    }
    if (b.order > a.order) {
        return -1;
    }
    return 0;
};

const EducationLevel: TranslatedComponent<EducationLevelProps> = ({ name, trls }) => {
    return (
        <NovaFilterWrapper title={trls[TrlKeys.title]}>
            <NovaFilterContent name={name} sortFunc={educationLevelSort} />
        </NovaFilterWrapper>
    );
};

export default translation(EducationLevel);
