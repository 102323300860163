import { FC, ReactNode, PropsWithChildren } from 'react';
import classnames from 'classnames';

import Text from 'bloko/blocks/text';

interface NovaFilterWrapperPros {
    title?: ReactNode;
    titleSideElement?: ReactNode;
    divider?: boolean;
}

const NovaFilterWrapper: FC<NovaFilterWrapperPros & PropsWithChildren> = ({
    title,
    titleSideElement,
    divider,
    children,
}) => (
    <fieldset
        className={classnames('novafilters-group-wrapper', { 'novafilters-group-wrapper_with-divider': divider })}
        data-qa="serp__novafilter-group"
    >
        {title && (
            <div className="novafilters-group-title" data-qa="serp__novafilter-group-title">
                <Text Element="span" strong>
                    <legend>{title}</legend>
                </Text>
                {!!titleSideElement && titleSideElement}
            </div>
        )}
        <div className="novafilters-group__items">{children}</div>
    </fieldset>
);

export default NovaFilterWrapper;
