import { ReactNode } from 'react';

import { Action, BottomSheet, BottomSheetFooter, Button, NavigationBar, SearchInput } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    save: 'novaModal.save',
    placeholder: 'search.clusters.input.placeholder',
};

export interface NovaBottomSheetProps {
    title: ReactNode;
    searchQuery: string;
    showModal: boolean;
    onClose: () => void;
    onChangeSearchQuery: (value: string) => void;
    showBack?: boolean;
    onBack: () => void;
    additionalOptions?: ReactNode;
    inputDataQa?: string;
}

const NovaBottomSheet: TranslatedComponent<NovaBottomSheetProps> = ({
    title,
    searchQuery,
    showModal,
    onClose,
    onChangeSearchQuery,
    showBack,
    onBack,
    additionalOptions,
    trls,
    children,
    inputDataQa,
}) => {
    return (
        <BottomSheet
            visible={showModal}
            header={
                <NavigationBar
                    title={title}
                    showDivider="always"
                    left={showBack ? <Action icon={ChevronLeftOutlinedSize24} onClick={onBack} /> : undefined}
                    right={<Action icon={CrossOutlinedSize24} onClick={showBack ? onClose : onBack} />}
                    options={
                        <>
                            <SearchInput
                                value={searchQuery}
                                placeholder={trls[TrlKeys.placeholder]}
                                onChange={onChangeSearchQuery}
                                data-qa={inputDataQa}
                            />
                            {additionalOptions}
                        </>
                    }
                />
            }
            footer={
                <BottomSheetFooter>
                    <Button mode="primary" style="accent" onClick={onClose}>
                        {trls[TrlKeys.save]}
                    </Button>
                </BottomSheetFooter>
            }
            onClose={onClose}
            height="full-screen"
        >
            {children}
        </BottomSheet>
    );
};

export default translation(NovaBottomSheet);
