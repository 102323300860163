import { useContext, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';
import { ModelData, AdditionalDefault } from 'bloko/common/tree/types';

import AreaId from 'HHC/areaId';
import { UserType } from 'src/models/userType';
import fetcher from 'src/utils/fetcher';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

const AREA_URL = '/shards/regions_tree';

declare global {
    interface FetcherGetApi {
        [AREA_URL]: {
            queryParams: {
                lang: string;
                site: string;
                rootAreaId: string | null;
                needSorting?: boolean;
            };
            response: { items: ModelData<AdditionalDefault>[] };
        };
    }
}

const areaTreeAction = makeSetStoreField('areaTree');

type TreeCacheType = Record<string, ModelData<AdditionalDefault>[]>;

interface UseFetchAreaTree {
    queryParams?: Partial<Pick<FetcherGetApi[typeof AREA_URL]['queryParams'], 'rootAreaId' | 'needSorting'>>;
    disableFetching?: boolean;
}

export default function useFetchAreaTree(args: UseFetchAreaTree = {}): void {
    const isZarplataPlatform = useIsZarplataPlatform();
    const userType = useSelector(({ userType }) => userType);
    const isEmployer = UserType.Employer === userType;

    const areaTreeCache = useRef<TreeCacheType>({});
    const dispatch = useDispatch();
    const lang = useContext(TranslationLangContext);
    const isRussianAreaRequest = isZarplataPlatform && isEmployer;

    const queryParams = useMemo(
        () => ({
            rootAreaId: args.queryParams?.rootAreaId || isRussianAreaRequest ? AreaId.Russia : null,
            ...args.queryParams,
        }),
        [args.queryParams, isRussianAreaRequest]
    );
    const disableFetching = args.disableFetching || false;

    useEffect(() => {
        if (disableFetching) {
            return;
        }

        const cacheKey = `${lang}${queryParams.rootAreaId || ''}`;

        if (!(cacheKey in areaTreeCache.current)) {
            areaTreeCache.current[cacheKey] = [];

            fetcher
                .get(AREA_URL, {
                    params: {
                        ...queryParams,
                        lang,
                        site: window.globalVars.siteId,
                    },
                })
                .then((areaTree) => {
                    areaTreeCache.current[cacheKey] = areaTree.items;
                    dispatch(areaTreeAction(areaTree.items));
                })
                .catch(() => {
                    delete areaTreeCache.current[cacheKey];
                });
        } else {
            dispatch(areaTreeAction(areaTreeCache.current[cacheKey]));
        }
    }, [lang, disableFetching, queryParams, dispatch]);
}
