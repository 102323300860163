import { NovaFilterSetting } from 'src/components/NovaFilters/novaFilterUtils';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

const useResetLink = (): string => {
    const basePath = useSelector((state) => state.searchClustersSettings.actionPath);
    const enableSimilarSavedSearch = useSelector((state) => state.vacancySearchResult.enableSimilarSavedSearch);
    const clusters = useSelector((state) => state.searchClusters);
    const isMapVacancySearch = useSelector((state) => state.isMapVacancySearch);
    const domainAreaId = useSelector((state) => state.locale.domainAreaId);

    if (enableSimilarSavedSearch) {
        const resumeFilter = clusters?.[NovaFilterKey.Resume];

        if (resumeFilter) {
            const groupKeys = Object.keys(resumeFilter.groups);
            const resumeHash = groupKeys[0] || '';
            return `${basePath}?resume=${resumeHash}&${NovaFilterSetting.ForceFiltersSaving}=true`;
        }
    }

    if (isMapVacancySearch) {
        return `${basePath}?area=${domainAreaId}`;
    }

    return basePath;
};

export default useResetLink;
