import { useState, ChangeEvent, ReactNode, FC } from 'react';

import CheckboxChip from 'bloko/blocks/chips/CheckboxChip';

import ChipsLayoutWrapper from 'src/components/NovaFilters/components/NovaChipsSelect/ChipsLayoutWrapper';

export enum ChipsSelectType {
    Single = 'single',
    Multi = 'multi',
}

export interface NovaChipsOption<T extends string> {
    id: T;
    title: ReactNode;
}

export interface NovaChipsSelectProps {
    name: string;
    options: NovaChipsOption<string>[];
    selected: string[];
    onChange: (values: string[]) => void;
    selectType?: ChipsSelectType;
}

const NovaChipsSelect: FC<NovaChipsSelectProps> = ({
    name,
    options,
    selected,
    onChange,
    selectType = ChipsSelectType.Single,
}) => {
    const [values, setValues] = useState([...selected]);
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (values.includes(value)) {
            if (selectType === ChipsSelectType.Single) {
                return;
            }
            const newValues = values.filter((item) => item !== value);
            setValues(newValues);
            onChange(newValues);
        } else {
            if (selectType === ChipsSelectType.Single) {
                setValues([value]);
                onChange([value]);
                return;
            }
            const newValues = [...values, value];
            setValues(newValues);
            onChange(newValues);
        }
    };
    return (
        <ChipsLayoutWrapper>
            {options.map(({ id, title }) => (
                <CheckboxChip
                    key={`${name}_${id}`}
                    data-qa={`serp__novafilter-xs-${name}-${id}`}
                    name={name}
                    onChange={handleChange}
                    checked={values.includes(id)}
                    value={id}
                    id={id}
                >
                    {title}
                </CheckboxChip>
            ))}
        </ChipsLayoutWrapper>
    );
};

export default NovaChipsSelect;
