import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

const sendClickAnalytics = (name: NovaFilterKey) => Analytics.sendHHEventButtonClick('reset_filter', { filter: name });

const TrlKeys = {
    text: 'search.filter.reset',
};

type Props = {
    name: NovaFilterKey;
    onReset: () => void;
};

const ResetFilter: TranslatedComponent<Props> = ({ name, onReset, trls }) => {
    const handleClick = useCallback(() => {
        onReset();
        sendClickAnalytics(name);
    }, [name, onReset]);

    return (
        <Link typography="label-2-regular" onClick={handleClick}>
            {trls[TrlKeys.text]}
        </Link>
    );
};

export default translation(ResetFilter);
