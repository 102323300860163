import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaFilter, { NovaFilterProps } from 'src/components/NovaFilters/components/NovaFilter';
import translation from 'src/components/translation';

const TrlKeys = {
    title: 'clusters.educationLevel',
};

const Education: TranslatedComponent<NovaFilterProps> = ({ trls, name }) => {
    return <NovaFilter title={trls[TrlKeys.title]} name={name} />;
};

export default translation(Education);
