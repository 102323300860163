import { FC, ComponentProps } from 'react';

import useMagritte from 'src/hooks/useMagritte';

import NovaFilterWithChartBloko from 'src/components/NovaFilters/components/Bloko/NovaFilterRange/NovaFilterWithChart';
import NovaFilterWithChartMagritte from 'src/components/NovaFilters/components/Magritte/NovaFilterWithChart';

const NovaFilterWithChart: FC<
    ComponentProps<typeof NovaFilterWithChartMagritte> | ComponentProps<typeof NovaFilterWithChartBloko>
> = (props) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? NovaFilterWithChartMagritte : NovaFilterWithChartBloko;

    return <Component {...props} />;
};

export default NovaFilterWithChart;
