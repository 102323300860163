import { FC, useState } from 'react';

import { Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';

import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaSwitchItem from 'src/components/NovaFilters/components/Magritte/NovaSwitchItem';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { LabelOption } from 'src/models/vacancySearchCriteria';

const Internship: FC = () => {
    // single filter group
    const name = LabelOption.Internship;
    const group = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.groups.internship);
    const selectedLabels = useSelector((state) => state.searchClusters[NovaFilterKey.Label]?.selectedValues);
    const [value, setValue] = useState(!!selectedLabels?.includes(LabelOption.Internship));

    // callbacks
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const onChangeInternship = (intershipChecked: boolean) => {
        const newSelectedLabels = intershipChecked
            ? [...selectedLabels, LabelOption.Internship]
            : selectedLabels.filter((label) => label !== LabelOption.Internship);

        filterUpdate(newSelectedLabels, NovaFilterKey.Label);
        setValue(intershipChecked);
    };

    // can be empty
    if (!group) {
        return null;
    }

    return (
        <MagritteNovaControl
            mobileView={
                <MagritteNovaSwitchItem
                    title={group.title}
                    checked={value}
                    onClick={() => {
                        onChangeInternship(!value);
                        sendCountsRequest();
                    }}
                    dataQa={`serp__novafilter-${name}-${group.id}`}
                />
            }
        >
            <MagritteNovaFilterItemWrapper
                left={
                    <MagritteCheckbox
                        name={name}
                        value={group.id}
                        dataQaCheckbox={`serp__novafilter-${name}-${group.id}`}
                        onChange={() => onChangeInternship(!value)}
                        checked={value}
                    />
                }
                title={group.title}
                disabled={group.disabled}
                count={group.count}
            />
        </MagritteNovaControl>
    );
};

export default Internship;
