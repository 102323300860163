import { useState, useCallback, useRef, FC } from 'react';

import ControlGroup from 'bloko/blocks/controlGroup';
import { FormItem } from 'bloko/blocks/form';
import NumberValidator from 'bloko/common/numberValidator';

import { NovaFilterKey, Range, NovaFilterRangeConvertedToStrings } from 'src/models/novaFilters';

import NovaFilterRangeInput, {
    ErrorProps,
    InputProps,
} from 'src/components/NovaFilters/components/Bloko/NovaFilterRange/Input';

export interface NovaFilterRangeTrls {
    from: string;
    to: string;
    fromTo: string;
    labelFrom: string;
    labelTo: string;
    onlyWithTitle: string;
    resumes: string;
}

interface InputsGroupProps {
    values: NovaFilterRangeConvertedToStrings;
    onChange: (data: NovaFilterRangeConvertedToStrings, forceUpdate?: boolean) => void;
    updateFilters: () => void;
    name: typeof NovaFilterKey.Salary | typeof NovaFilterKey.Age;
    trls: NovaFilterRangeTrls;
}

const InputsGroup: FC<InputsGroupProps> = ({ values, onChange, updateFilters, name, trls }) => {
    const [inputError, setInputError] = useState<ErrorProps>({ scope: null, type: null });
    const clearError = () => setInputError({ scope: null, type: null });
    const fromInputRef = useRef<HTMLInputElement>(null);
    const toInputRef = useRef<HTMLInputElement>(null);

    const handleChange = useCallback<InputProps['onChange']>(
        (value, scope) => {
            const [error] = NumberValidator.validate(value, {
                groupSeparator: '',
                decimalLength: 0,
            });

            if (!error) {
                onChange({ ...values, [scope]: value });
                clearError();
            } else {
                setInputError({ scope, type: error });
            }
        },
        [onChange, values]
    );

    return (
        <FormItem>
            <ControlGroup>
                <NovaFilterRangeInput
                    name={name}
                    value={`${values[Range.From] ?? ''}`}
                    scope={Range.From}
                    label={trls.labelFrom}
                    onChange={handleChange}
                    updateFilters={updateFilters}
                    error={{ inputError, clearError }}
                    inputRef={fromInputRef}
                />
                <NovaFilterRangeInput
                    name={name}
                    value={`${values[Range.To] ?? ''}`}
                    scope={Range.To}
                    label={trls.labelTo}
                    onChange={handleChange}
                    updateFilters={updateFilters}
                    error={{ inputError, clearError }}
                    inputRef={toInputRef}
                />
            </ControlGroup>
        </FormItem>
    );
};

export default InputsGroup;
