import { FC } from 'react';

import NovaFilterMagritte from 'src/components/NovaFilters/components/Magritte/NovaFilter';
import { FilterContent } from 'src/components/NovaFilters/components/NovaFilterContent';

export interface NovaFilterProps extends FilterContent {
    title: string;
}

const NovaFilter: FC<NovaFilterProps> = (props) => {
    return <NovaFilterMagritte {...props} />;
};

export default NovaFilter;
