import { useState, useEffect } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import SearchType from 'src/components/NovaFilters/SearchType';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';

import ProfessionalRoleDesktop from 'src/components/NovaFilters/ProfessionalRole/ProfessionalRoleDesktop';
import ProfessionalRolesMobile from 'src/components/NovaFilters/ProfessionalRole/ProfessionalRoleMobile';
import ProfessionalRoleMobileResume from 'src/components/NovaFilters/ProfessionalRole/ProfessionalRoleMobileResume';

const TrlKeys = {
    title: 'resume.search.field.professional_role',
};

const ProfessionalRoleWrapper: TranslatedComponent = ({ trls }) => {
    const isResumeSearch = useSelector((state) => state.searchClustersSettings.type) === SearchType.Resume;
    const { isMobile } = useBreakpoint();

    // global data
    const filterUpdate = useNovaFilterUpdate();
    const { groups, selectedValues } = useSelector((state) => {
        const { groups, selectedValues } = state.searchClusters[NovaFilterKey.ProfessionalRole];
        return { groups, selectedValues: selectedValues?.map((item) => `${item}`) || [] };
    });

    // tree data
    const professionalRoleTree = useSelector(({ professionalRoleTree }) => professionalRoleTree);
    const [collection, setCollection] = useState(fromTree(professionalRoleTree.items));

    useEffect(() => {
        setCollection(fromTree(professionalRoleTree.items));
    }, [professionalRoleTree]);

    if (isResumeSearch && isMobile) {
        return (
            <ProfessionalRoleMobileResume
                title={trls[TrlKeys.title]}
                initialValues={selectedValues}
                collection={collection}
                groups={groups}
            />
        );
    }

    return (
        <MagritteNovaControl
            mobileView={
                <ProfessionalRolesMobile
                    title={trls[TrlKeys.title]}
                    initialValues={selectedValues}
                    collection={collection}
                />
            }
        >
            <ProfessionalRoleDesktop
                title={trls[TrlKeys.title]}
                selectedValues={selectedValues}
                groups={groups}
                collection={collection}
                onChange={(newValues: string[]) => {
                    filterUpdate(newValues, NovaFilterKey.ProfessionalRole);
                }}
            />
        </MagritteNovaControl>
    );
};

export default translation(ProfessionalRoleWrapper);
