import { useState, Fragment, FC } from 'react';

import { Link, VSpacing } from '@hh.ru/magritte-ui';
import ButtonBloko from 'bloko/blocks/button';
import LinkBloko from 'bloko/blocks/link';

import useMagritte from 'src/hooks/useMagritte';
import { AdditionalFiltersOrderKey } from 'src/hooks/useNovaFiltersOrderForResume';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { Criteria as ResumeCriteria } from 'src/models/resumeSearchCriteria';

import { additionalNovaFilterMap } from 'src/components/NovaFilters/hooks/useNovaFilterMap';

interface Props {
    criteria: ResumeCriteria;
    additionalFiltersOrder: AdditionalFiltersOrderKey[];
}

const AdditionalNovaFilters: FC<Props> = ({ criteria, additionalFiltersOrder }) => {
    const isMagritte = useMagritte();

    const clusters = useSelector((state) => state.searchClusters);
    const [activeFilters, setActiveFilters] = useState(
        additionalFiltersOrder.filter((filterName) => criteria[filterName]?.length > 0 || isMagritte)
    );
    return (
        <Fragment>
            {activeFilters.map((activeFilter) => {
                if (!clusters[activeFilter]) {
                    return null;
                }
                const ComponentFilterView = additionalNovaFilterMap[activeFilter];
                return <ComponentFilterView key={activeFilter} active />;
            })}
            {additionalFiltersOrder.length !== activeFilters.length && (
                <div className="novafilters-group-wrapper">
                    <div className="novafilters-group-links">
                        {additionalFiltersOrder.map((filterName) => {
                            if (activeFilters.includes(filterName)) {
                                return null;
                            }
                            if (
                                [NovaFilterKey.DriverLicenseTypes, NovaFilterKey.Language].includes(filterName) &&
                                !clusters[filterName]
                            ) {
                                return null;
                            }
                            const ComponentLinkView = additionalNovaFilterMap[filterName];
                            return (
                                <div key={filterName}>
                                    {isMagritte ? (
                                        <Link
                                            Element="button"
                                            onClick={() => setActiveFilters([...activeFilters, filterName])}
                                            data-qa={`resume-search-additional-filter-${filterName}`}
                                        >
                                            <ComponentLinkView key={filterName} active={false} />
                                        </Link>
                                    ) : (
                                        <LinkBloko
                                            Element={ButtonBloko}
                                            onClick={() => setActiveFilters([...activeFilters, filterName])}
                                            data-qa={`resume-search-additional-filter-${filterName}`}
                                        >
                                            <ComponentLinkView key={filterName} active={false} />
                                        </LinkBloko>
                                    )}
                                    <VSpacing default={4} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default AdditionalNovaFilters;
