import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsItem from 'src/components/NovaFilters/components/Magritte/NovaChipsItem';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'src/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, NovaFilters } from 'src/models/novaFilters';

import MagritteTotalFiltersCount from 'src/components/NovaFilters/vacancies/TotalFiltersCount';

const getResumeTitle = (cluster: NovaFilters[typeof NovaFilterKey.Resume], hash: string): string => {
    if (!hash) {
        return '';
    }
    return cluster.groups?.[hash].title;
};

const TrlKeys = {
    filterName: 'novafilters.similarSavedSearch.filter',
    title: 'vacancySearch.clusters.similar.resume',
};

const SimilarResume: TranslatedComponent = ({ trls, ...prop }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const { enableSimilarSavedSearch, hash, cluster } = useSelector((state) => {
        const resume = state.searchClusters?.[NovaFilterKey.Resume];
        return {
            enableSimilarSavedSearch: state.vacancySearchResult.enableSimilarSavedSearch,
            totalUsedFilters: state.vacancySearchResult.totalUsedFilters,
            hash: resume && resume.selectedValues.length > 0 ? resume.selectedValues[0] : '',
            cluster: resume,
        };
    });
    if (!cluster || Object.values(cluster.groups).length === 0 || !cluster.selectedValues.length) {
        return null;
    }
    const resumeTitle = getResumeTitle(cluster, hash);

    return (
        <MagritteNovaControl
            mobileView={
                <MagritteNovaChipsItem
                    title={trls[TrlKeys.title]}
                    value={resumeTitle}
                    onDelete={() => {
                        filterUpdate([], NovaFilterKey.Resume);
                        sendCountsRequest();
                    }}
                />
            }
        >
            {enableSimilarSavedSearch && <MagritteTotalFiltersCount />}
            <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                <MagritteNovaFilterContent name={NovaFilterKey.Resume} {...prop} />
            </MagritteNovaFilterWrapper>
        </MagritteNovaControl>
    );
};

export default translation(SimilarResume);
