import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import numberFormatter from 'bloko/common/numberFormatter';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    titleFoundOne: 'vacancySearch.title.found.one',
    titleFoundSome: 'vacancySearch.title.found.some',
    titleFoundMany: 'vacancySearch.title.found.many',
    titleFoundZero: 'vacancySearch.title.found.zero',
};

const TotalVacanciesFound: TranslatedComponent<{ count: number }> = ({ count, trls }) => (
    <Conversion
        value={count}
        zero={trls[TrlKeys.titleFoundZero]}
        one={trls[TrlKeys.titleFoundOne]}
        some={trls[TrlKeys.titleFoundSome]}
        many={trls[TrlKeys.titleFoundMany]}
        hasValue={false}
        format={(trl) =>
            format(trl, {
                '{0}': numberFormatter.format(String(count), {
                    groupSeparator: NON_BREAKING_SPACE,
                }),
            })
        }
    />
);

export default translation(TotalVacanciesFound);
