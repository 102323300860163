import { FC } from 'react';

import { FormLegend, FormSpacer } from 'bloko/blocks/form';
import { CrossScaleSmallEnclosedFalse, IconDynamic, IconLink, IconColor } from 'bloko/blocks/icon';

interface RemoveButtonProps {
    onClick: () => void;
    dataQa: string;
}

const RemoveButton: FC<RemoveButtonProps> = ({ onClick, dataQa }) => (
    <FormLegend>
        <FormSpacer>
            <IconDynamic>
                <IconLink Element="button" type="button" onClick={onClick} data-qa={dataQa}>
                    <CrossScaleSmallEnclosedFalse initial={IconColor.Gray50} highlighted={IconColor.Gray60} />
                </IconLink>
            </IconDynamic>
        </FormSpacer>
    </FormLegend>
);

export default RemoveButton;
