import { forwardRef, ForwardRefRenderFunction, PropsWithChildren } from 'react';

import useMagritte from 'src/hooks/useMagritte';

import FilterListBloko from 'src/components/NovaFilters/components/Bloko/FilterList';
import FilterListMagritte from 'src/components/NovaFilters/components/Magritte/FilterList';

interface FilterListProps {
    expanded?: boolean;
    len?: number;
    scrollable?: boolean;
}

const FilterList: ForwardRefRenderFunction<HTMLUListElement, PropsWithChildren<FilterListProps>> = (props, ref) => {
    const isMagritte = useMagritte();
    const Component = isMagritte ? FilterListMagritte : FilterListBloko;

    return <Component ref={ref} {...props} />;
};

export default forwardRef(FilterList);
