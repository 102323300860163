import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

const showSearchPreferenceAction = makeSetStoreField('searchPreference');

export default (): ((showSearchPreference: boolean) => void) => {
    const dispatch = useDispatch();
    return useCallback(
        (showSearchPreference) => {
            dispatch(
                showSearchPreferenceAction({
                    isShown: showSearchPreference,
                })
            );
        },
        [dispatch]
    );
};
