import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsItem from 'src/components/NovaFilters/components/Magritte/NovaChipsItem';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'src/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterVacancyId, NovaFilterKey } from 'src/models/novaFilters';

const getVacancyName = (cluster: NovaFilterVacancyId): string => {
    const vacancyId = cluster.selectedValues?.[0] || '';
    if (!vacancyId) {
        return '';
    }
    return cluster.groups?.[vacancyId].name;
};

const TrlKeys = {
    title: 'vacancySearch.clusters.suitable.vacancy',
};

interface Props {
    name: typeof NovaFilterKey.VacancyId;
}

const VacancyId: TranslatedComponent<Props> = ({ name, trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const cluster = useSelector(({ searchClusters }) => searchClusters?.[name]);

    if (!cluster || Object.values(cluster.groups).length === 0 || !cluster.selectedValues.length) {
        return null;
    }

    const vacancyName = getVacancyName(cluster);

    return (
        <MagritteNovaControl
            mobileView={
                <MagritteNovaChipsItem
                    title={trls[TrlKeys.title]}
                    value={vacancyName}
                    onDelete={() => {
                        filterUpdate([], NovaFilterKey.VacancyId);
                        sendCountsRequest();
                    }}
                />
            }
        >
            <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                <MagritteNovaFilterContent name={name} />
            </MagritteNovaFilterWrapper>
        </MagritteNovaControl>
    );
};

export default translation(VacancyId);
